<template>
  <v-card
    class="fill-height"
    :class="{
      'elevation-0 grey lighten-3': !enabledCard,
      'elevation-10': enabledCard,
      [color]: enabledCard,
      'no-cursor': !cickliable
    }"
    @click="handlerClick"
  >
    <div class="bg-contador-linear"></div>
    <v-card-text class="pa-sm-2 pa-md-4" :class="enabledCard ? 'white--text' :  'blue-grey--text text--lighten-2'">
      <v-row class="d-flex justify-space-between flex-column">
        <v-col
          cols="12"
          class="d-flex aling-center justify-space-between pb-1 gap-3"
        >
        <span
          class="text-sm-h6 text-md-h5 font-weight-medium text-uppercase d-flex align-center"
          v-text="label"
        />
          <v-avatar
            color="grey lighten-5"
            size="40"
            class="rounded-xl"
          >
            <v-icon
              size="25"
              :color="colorIcon"
              v-text="icon"
            />
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          class="d-flex pt-0"
        >
          <span class="text-count font-weight-bold">{{ prefix }} {{ count | formatMoneda }}</span>
          <slot></slot>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-center justify-space-between"
        >
        <div class="d-flex gap-1">
            <v-icon
              size="20"
              :color="enabledCard ? 'white' : colorIcon"
              v-text="details.icon"
            />
            <span
              class="text-h6 font-weight-medium text-uppercase d-flex align-center"
              v-text="details.label"
            />
          </div>
          <span class="text-h4 font-weight-bold">{{ details.prefix }}{{ details.amount | formatMoneda }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'Contador',
  filters: {
    formatMoneda (value) {
      return value.toLocaleString('es-ES')
    },
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    icon: String,
    color: String,
    colorIcon: String,
    label: String,
    cickliable: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    details: {
      type: Object,
      default: () => ({
        label: '',
        amount: '',
        icon: '',
        prefix: ''
      })
    }
  },
  data() {
    return {
      valueCount: 0,
    }
  },
  computed:{
    enabledCard() {
      return this.count && this.count > 0
    },
  },
  methods: {
    handlerClick () {
      if(!this.cickliable) return;

      this.$emit('click')
    }
  }
}
</script>
<style lang="sass">
.bg-contador-linear
    position: absolute
    top: 0
    left:0
    background-image: repeating-linear-gradient(-55deg, transparent 0 14px, white 0 15px)
    width: 100%
    height: 100%
    opacity: .08
.title-no-wrap
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-count
  font-size: calc(1em + 1vw)

.no-cursor
  cursor: inherit !important

.text-height
  height: 50px !important
</style>
